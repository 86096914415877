import React from 'react';
import { Button } from 'antd';
import { useIntl } from 'react-intl';

import './savePanel.less';

interface SavePanelPropsI {
  cancelText?: string;
  submitText?: string;
  loading?: boolean;
  onCancel?: () => void;
}

const SavePanel = ({
  cancelText,
  submitText,
  loading,
  onCancel,
}: SavePanelPropsI) => {
  const { formatMessage } = useIntl();

  return (
    <div className="save-panel">
      <Button
        className="save-panel__button"
        onClick={onCancel}
        disabled={loading}
      >
        {cancelText || formatMessage({ id: 'button.Cancel' })}
      </Button>
      <Button
        className="save-panel__button"
        type="primary"
        htmlType="submit"
        loading={loading}
      >
        {submitText || formatMessage({ id: 'button.Save' })}
      </Button>
    </div>
  );
};

export default SavePanel;
