import {
  FinanceSystem,
  OrderContractStatus,
  OrderStatus,
  GroupByPeriod,
} from 'api';
import { CustomerType } from 'components';
import React, { createContext, useContext, useState } from 'react';
import { Moment } from 'moment';
import {
  getLocalB2COrdersFilters,
  setLocalB2CFilterByOrdersType,
  setLocalB2CFilterByProduct,
  setLocalB2CFilterByProject,
  setLocalB2CFilterByContractStatus,
  setLocalB2CFilterByOrderStatus,
  setLocalB2CFilterByInvoicing,
  setLocalB2CFilterByCreditCheck,
  setLocalB2CFilterBySalesChannel,
  setLocalB2CFilterByAddon,
  setLocalB2CFilterByBundle,
  setLocalB2CFilterSearchName,
  setLocalB2CFilterSearchValue,
  setLocalB2COrdersPeriod,
  setLocalB2COrdersDateRange,
  setLocalB2COrdersGroupedBy,
  setLocalB2CFilterBySaleStatus,
  setLocalB2COrdersRecordsLimit,
  setLocalB2COrdersPickerDateType,
  resetB2CLocalFilters,
  filtersCollapseTogle,
} from 'utils';

interface B2COrdersFilterContextI {
  localPeriod: string | undefined;
  localDateRange: Moment[] | null[];
  localGroupedBy: GroupByPeriod;
  orderType: CustomerType;
  localProductIdIn: string | undefined;
  localProjectIdIn: string | undefined;
  localContractStatusIn: OrderContractStatus | undefined;
  localOrderStatusIn: OrderStatus | undefined | 'DELETED';
  localFinanceSystemEq: FinanceSystem | undefined;
  localCreditCheckCodeEq: number | undefined;
  localSalesChannelIdIn: string | undefined;
  localBundleIdIn: string | undefined;
  localAddonIdIn: string | undefined;
  localSearchBy: string | number | undefined | any;
  localSearchValue: string | undefined;
  localSaleStatusEq: string | number | undefined | any;
  localRecordsLimit: number | undefined;
  isLocalFilterTouched: boolean | undefined;
  isCollapseOpen: boolean | undefined;
  localDateTypeFilter: string | undefined;
  setFilterByProductIn: (productInput?: string | null | undefined) => void;
  setFilterByOrderTypeIs: (
    orderTypeInput?: string | null | undefined | CustomerType
  ) => void;
  setFilterByProjectIn: (projectInput?: string | null | undefined) => void;
  setFilterByContractStatusEq: (
    contractStatusInput?: string | null | undefined
  ) => void;
  setFilterByOrderStatus: (
    orderStatusInput?: string | null | undefined
  ) => void;
  setFilterBySaleStatus: (saleStatusInput?: string | null | undefined) => void;
  setFilterByInvoicingType: (
    invoicingInput?: string | null | undefined
  ) => void;
  setFilterByCreditCheckCode: (
    creditCheckInput?: string | null | undefined
  ) => void;
  setFilterByBundleIn: (bundleInput?: string | null | undefined) => void;
  setFilterByAddonIn: (addonInput?: string | null | undefined) => void;
  setFilterBySalesChannel: (
    salesChannelInput?: string | null | undefined
  ) => void;
  setFilterBySearchName: (searchNameInput?: string | null | undefined) => void;
  setFilterBySearchValue: (
    searchValueInput?: string | null | undefined
  ) => void;
  setB2CRecordLimit: (limit?: number | undefined) => void;
  setB2CCollapse: (value?: boolean) => void;
  setB2COrdersPeriod: (period: string) => void;
  setB2COrdersDateRange: (dateRange: Moment[] | null[] | undefined) => void;
  setB2COrdersGroupedBy: (groupedBy: GroupByPeriod) => void;
  setB2CLocalDateType: (dateType: string | undefined) => void;
  resetB2COrdersFilters: () => void;
}

const b2cOrdersFilterContext = createContext<B2COrdersFilterContextI>({
  localPeriod: 'currentMonth',
  localDateRange: [null, null],
  localGroupedBy: GroupByPeriod.Day,
  orderType: CustomerType.B2C,
  localProductIdIn: undefined,
  localProjectIdIn: undefined,
  localContractStatusIn: undefined,
  localOrderStatusIn: undefined,
  localFinanceSystemEq: undefined,
  localCreditCheckCodeEq: undefined,
  localSalesChannelIdIn: undefined,
  localBundleIdIn: undefined,
  localAddonIdIn: undefined,
  localSearchBy: 'customerLike',
  localSearchValue: undefined,
  localSaleStatusEq: undefined,
  localRecordsLimit: 10,
  isLocalFilterTouched: false,
  isCollapseOpen: false,
  localDateTypeFilter: 'salesAt',
  setFilterByProductIn: () => undefined,
  setFilterByOrderTypeIs: () => undefined,
  setFilterByProjectIn: () => undefined,
  setFilterByContractStatusEq: () => undefined,
  setFilterByOrderStatus: () => undefined,
  setFilterBySaleStatus: () => undefined,
  setFilterByInvoicingType: () => undefined,
  setFilterByCreditCheckCode: () => undefined,
  setFilterBySalesChannel: () => undefined,
  setFilterByBundleIn: () => undefined,
  setFilterByAddonIn: () => undefined,
  setFilterBySearchName: () => undefined,
  setFilterBySearchValue: () => undefined,
  resetB2COrdersFilters: () => undefined,
  setB2COrdersPeriod: () => undefined,
  setB2COrdersDateRange: () => undefined,
  setB2COrdersGroupedBy: () => undefined,
  setB2CRecordLimit: () => undefined,
  setB2CCollapse: () => undefined,
  setB2CLocalDateType: () => undefined,
});

const { Provider } = b2cOrdersFilterContext;

const B2COrdersFilterProvider = (props: any) => {
  const {
    localPeriod,
    localDateRange,
    localGroupedBy,
    orderType,
    localProductIdIn,
    localProjectIdIn,
    localContractStatusIn,
    localFinanceSystemEq,
    localCreditCheckCodeEq,
    localOrderStatusIn,
    localSalesChannelIdIn,
    localBundleIdIn,
    localAddonIdIn,
    localSearchBy,
    localSearchValue,
    localSaleStatusEq,
    localRecordsLimit,
    isFilterTouched,
    isLocalCollapseOpen,
    localPickerDateType,
  } = getLocalB2COrdersFilters();

  const [periodValue, setPeriodState] = useState(
    localPeriod ? localPeriod.value : 'allTime'
  );
  const [dateRangeValue, setDateRangeState] = useState(
    localDateRange ? localDateRange.value : [null, null]
  );
  const [groupedByValue, setGroupedByState] = useState(
    localGroupedBy || GroupByPeriod.Day
  );
  const [orderTypeValue, setOrderTypeState] = useState(
    orderType || CustomerType.B2C
  );
  const [localProductIdInValue, setLocalProductIdInState] = useState(
    localProductIdIn ? localProductIdIn.value : undefined
  );
  const [localProjectIdInValue, setLocalProjectIdInState] = useState(
    localProjectIdIn ? localProjectIdIn.value : undefined
  );
  const [localContractStatusInValue, setLocalContractStatusInState] = useState(
    localContractStatusIn ? localContractStatusIn.value : undefined
  );
  const [localFinanceSystemEqValue, setLocalFinanceSystemEqState] = useState(
    localFinanceSystemEq ? localFinanceSystemEq.value : undefined
  );
  const [localCreditCheckCodeEqValue, setLocalCreditCheckCodeEqState] =
    useState(localCreditCheckCodeEq ? localCreditCheckCodeEq.value : undefined);
  const [locallOrderStatusInValue, setLocallOrderStatusInState] = useState(
    localOrderStatusIn ? localOrderStatusIn.value : undefined
  );
  const [locallBundleIdInValue, setLocallBundleIdInState] = useState(
    localBundleIdIn ? localBundleIdIn.value : undefined
  );
  const [locallAddonIdInValue, setLocallAddonIdInState] = useState(
    localAddonIdIn ? localAddonIdIn.value : undefined
  );
  const [locallSalesChannelIdInValue, setLocallSalesChannelIdInState] =
    useState(localSalesChannelIdIn ? localSalesChannelIdIn.value : undefined);
  const [locallSearchByValue, setLocallSearchByState] = useState(
    localSearchBy ? localSearchBy.value : 'customerLike'
  );
  const [locallSearchValue, setLocallSearchValueState] = useState(
    localSearchValue ? localSearchValue.value : undefined
  );
  const [localSaleStatusValue, setLocalSaleStatusEqValueState] = useState(
    localSaleStatusEq ? localSaleStatusEq.value : undefined
  );
  const [localRecordLimitValue, setLocalRecordLimitEqValueState] = useState(
    localRecordsLimit ? localRecordsLimit.value : 10
  );
  const [localCollapseValue, setLocalCollapseState] = useState(
    isLocalCollapseOpen !== undefined ? isLocalCollapseOpen : false
  );
  const [localDateTypeValue, setLocalDateTypeState] = useState(
    localPickerDateType ? localPickerDateType.value : 'salesAt'
  );

  const setFilterByOrderTypeIs = (orderTypeInput?: string) => {
    setOrderTypeState(orderTypeInput);
    setLocalB2CFilterByOrdersType(orderTypeInput);
  };

  const setFilterByProductIn = (productInput?: string) => {
    setLocalProductIdInState(productInput);
    setLocalB2CFilterByProduct(productInput);
  };

  const setFilterByProjectIn = (projectInput?: string) => {
    setLocalProjectIdInState(projectInput);
    setLocalB2CFilterByProject(projectInput);
  };

  const setFilterByContractStatusEq = (
    contractStatusInput?: string | undefined
  ) => {
    setLocalContractStatusInState(contractStatusInput);
    setLocalB2CFilterByContractStatus(contractStatusInput);
    if (!contractStatusInput) {
      setFilterBySaleStatus(undefined);
    }
  };

  const setFilterByInvoicingType = (
    localFinanceSystemInput?: string | undefined
  ) => {
    setLocalFinanceSystemEqState(localFinanceSystemInput);
    setLocalB2CFilterByInvoicing(localFinanceSystemInput);
  };

  const setFilterByCreditCheckCode = (
    localCreditCheckInput?: string | undefined
  ) => {
    setLocalCreditCheckCodeEqState(localCreditCheckInput);
    setLocalB2CFilterByCreditCheck(localCreditCheckInput);
  };

  const setFilterByOrderStatus = (
    localOrderStatusInput?: string | undefined
  ) => {
    setLocallOrderStatusInState(localOrderStatusInput);
    setLocalB2CFilterByOrderStatus(localOrderStatusInput);
  };
  const setFilterBySalesChannel = (
    localSalesChannelInput?: string | undefined
  ) => {
    setLocallSalesChannelIdInState(localSalesChannelInput);
    setLocalB2CFilterBySalesChannel(localSalesChannelInput);
  };
  const setFilterByBundleIn = (bundleInput?: string | undefined) => {
    setLocallBundleIdInState(bundleInput);
    setLocalB2CFilterByBundle(bundleInput);
  };
  const setFilterByAddonIn = (addonInput?: string | undefined) => {
    setLocallAddonIdInState(addonInput);
    setLocalB2CFilterByAddon(addonInput);
  };
  const setFilterBySearchName = (localSearchByInput?: string | undefined) => {
    setLocallSearchByState(localSearchByInput);
    setLocalB2CFilterSearchName(localSearchByInput);
  };
  const setFilterBySearchValue = (
    localSearchValueInput?: string | undefined
  ) => {
    setLocallSearchValueState(localSearchValueInput);
    setLocalB2CFilterSearchValue(localSearchValueInput);
  };
  const setFilterBySaleStatus = (localSaleStatusInput?: string | undefined) => {
    setLocalSaleStatusEqValueState(localSaleStatusInput);
    setLocalB2CFilterBySaleStatus(localSaleStatusInput);
  };
  const setB2COrdersDateRange = (dateRange: Moment[] | null[] | undefined) => {
    setDateRangeState(dateRange);
    setLocalB2COrdersDateRange(dateRange);
  };

  const setB2COrdersGroupedBy = (groupedBy: GroupByPeriod) => {
    setGroupedByState(groupedBy);
    setLocalB2COrdersGroupedBy(groupedBy);
  };

  const setB2COrdersPeriod = (period?: string | undefined) => {
    setPeriodState(period);
    setLocalB2COrdersPeriod(period);
  };

  const setB2CRecordLimit = (limit: number | undefined) => {
    setLocalRecordLimitEqValueState(limit);
    setLocalB2COrdersRecordsLimit(limit);
  };

  const setB2CCollapse = (value?: boolean) => {
    setLocalCollapseState(value);
    filtersCollapseTogle(value);
  };

  const setB2CLocalDateType = (dateType?: string) => {
    setLocalDateTypeState(dateType);
    setLocalB2COrdersPickerDateType(dateType);
  };

  const resetB2COrdersFilters = () => {
    setOrderTypeState('B2C');
    setLocalProductIdInState(undefined);
    setLocalProjectIdInState(undefined);
    setLocalContractStatusInState(undefined);
    setFilterBySaleStatus(undefined);
    setLocalFinanceSystemEqState(undefined);
    setLocalCreditCheckCodeEqState(undefined);
    setLocallOrderStatusInState(undefined);
    setLocallSalesChannelIdInState(undefined);
    setLocallSearchByState('customerLike');
    setLocallSearchValueState(undefined);
    setDateRangeState([null, null]);
    setPeriodState('allTime');
    setB2CLocalDateType('salesAt');
    setB2CRecordLimit(10);
    setFilterByBundleIn(undefined);
    setFilterByAddonIn(undefined);
    resetB2CLocalFilters();
  };

  return (
    <Provider
      value={{
        isLocalFilterTouched: isFilterTouched,
        isCollapseOpen: localCollapseValue,
        localPeriod: periodValue,
        localDateRange: dateRangeValue,
        localGroupedByValue: groupedByValue,
        orderType: orderTypeValue,
        localProductIdIn: localProductIdInValue,
        localProjectIdIn: localProjectIdInValue,
        localContractStatusIn: localContractStatusInValue,
        localFinanceSystemEq: localFinanceSystemEqValue,
        localCreditCheckCodeEq: localCreditCheckCodeEqValue,
        localOrderStatusIn: locallOrderStatusInValue,
        localSalesChannelIdIn: locallSalesChannelIdInValue,
        localBundleIdIn: locallBundleIdInValue,
        localAddonIdIn: locallAddonIdInValue,
        localSearchBy: locallSearchByValue,
        localSearchValue: locallSearchValue,
        localSaleStatusEq: localSaleStatusValue,
        localRecordsLimit: localRecordLimitValue,
        localDateTypeFilter: localDateTypeValue,
        setFilterByOrderTypeIs,
        setFilterByProductIn,
        setFilterByContractStatusEq,
        setFilterByInvoicingType,
        setFilterByCreditCheckCode,
        setFilterBySaleStatus,
        setFilterByOrderStatus,
        setFilterBySalesChannel,
        setFilterByBundleIn,
        setFilterByAddonIn,
        setFilterBySearchName,
        setFilterBySearchValue,
        setFilterByProjectIn,
        setB2COrdersDateRange,
        setB2COrdersGroupedBy,
        setB2COrdersPeriod,
        setB2CRecordLimit,
        resetB2COrdersFilters,
        setB2CCollapse,
        setB2CLocalDateType,
      }}
      {...props}
    />
  );
};

const useB2COrdersFilterContext = () => useContext(b2cOrdersFilterContext);

export {
  b2cOrdersFilterContext,
  B2COrdersFilterProvider,
  useB2COrdersFilterContext,
};
