import React from 'react';
import { GetCustomersForListQuery } from 'api';

import { generatePath, useNavigate } from 'react-router-dom';
import { Table } from 'components';
import { columns } from './constants';
import { Links } from '../../../settings';

interface CustomersTablePropsI {
  limit: number;
  total?: number;
  loading: boolean;
  currentPage: number;
  customers: GetCustomersForListQuery['getCustomersForList']['nodes'];
  fetchMore: (page: number) => void;
  onChange: (pagination: any, filters: any, sorter: any) => void;
  pageSizeOptions?: number[];
  onShowSizeChange?: (value: number | undefined) => void;
}

const CustomersTable = ({
  total,
  limit,
  loading,
  onChange,
  customers,
  fetchMore,
  currentPage,
  pageSizeOptions,
  onShowSizeChange,
}: CustomersTablePropsI) => {
  const navigate = useNavigate();

  const handleCustomerView = (id: string | number) =>
    navigate(generatePath(Links.customers.details, { id }));

  return (
    <div className="customers-table">
      <Table
        dataSource={customers}
        currentPage={currentPage}
        total={total}
        limit={limit}
        fetchMore={fetchMore}
        columns={columns}
        rowKey="_id"
        onRow={({ _id }) => ({
          onClick: () => handleCustomerView(_id),
        })}
        onChange={onChange}
        loading={loading}
        pageSizeOptions={pageSizeOptions}
        setRecLimit={onShowSizeChange}
      />
    </div>
  );
};

export default CustomersTable;
