import React, { useState, useCallback } from 'react';
import { Tooltip } from 'antd';
import { useIntl } from 'react-intl';
import {
  Tag,
  Table,
  Column,
  ItemsPopover,
  EditAddonModal,
  ModalWindow,
} from 'components';
import {
  DotsIcon,
  EditIcon,
  NoCommissionIcon,
  ProjectSettingsIcon,
  TrashIcon,
} from 'assets/icons';
import {
  ProductAddonStatus,
  ProductAddonFragment,
  ProductStatus,
  RbacResourceName,
  useDeleteProductAddonMutation,
  useUpdateProductAddonMutation,
} from 'api';
import { usePermission } from 'hooks';
import { useErrorsContext } from 'contexts';

import './addonsTable.less';
import { generatePath, useNavigate } from 'react-router-dom';
import { Links } from 'settings';

interface AddonsTablePropsI {
  limit: number;
  total?: number;
  dataLoading: boolean;
  currentPage: number;
  showProject?: boolean;
  addons: ProductAddonFragment[];
  fetchMore: (page: number) => void;
  refetch: () => void;
  onChange: (pagination: any, filters: any, sorter: any) => void;
}

const AddonsTable = ({
  total,
  limit,
  dataLoading,
  addons,
  onChange,
  fetchMore,
  currentPage,
  showProject,
  refetch,
}: AddonsTablePropsI) => {
  const [editProductAddonVisible, setEditProductAddonVisible] = useState(false);
  const [editInfo, setEditInfo] = useState<ProductAddonFragment>();
  const [addonId, setAddonId] = useState<string>('');
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);

  const navigate = useNavigate();

  const handleAddonView = (e: any, id: string | number) => {
    if (
      !e.target.closest('.product-addons-table__menu') &&
      !e.target.closest('.ant-popover ')
    ) {
      navigate(generatePath(Links.addons.details, { id: String(id) }));
    }
  };

  const { setErrors } = useErrorsContext();

  const { formatMessage } = useIntl();

  const { isPermitted: isPermittedManaging } = usePermission(
    RbacResourceName.ProductAddonManaging,
  );

  const [updateProductAddon] = useUpdateProductAddonMutation({
    onCompleted({ updateProductAddon }) {
      const { status, errors } = updateProductAddon || {};
      const success = status === 'SUCCESS';

      if (success) {
        return refetch();
      }

      setErrors(errors);
    },
  });

  const [deleteProductAddon, { loading }] = useDeleteProductAddonMutation({
    variables: {
      input: { _id: addonId },
    },
    onCompleted({ deleteProductAddon }) {
      const { status, errors } = deleteProductAddon;
      const success = status === 'SUCCESS';

      if (success) {
        setDeleteModalVisible(false);
        setAddonId('');
        return refetch();
      }

      setErrors(errors);
    },
  });
  const handleMenuClick = useCallback(
    (value: string, _id?: string, productAddon?: ProductAddonFragment) => {
      if (value === 'edit') {
        toggleEditVisible(productAddon);
      }

      if (_id && value === 'toggle') {
        updateProductAddon({
          variables: {
            input: {
              _id,
              status:
                productAddon?.status === ProductAddonStatus.Active
                  ? ProductAddonStatus.Inactive
                  : ProductAddonStatus.Active,
            },
          },
        });
      }

      if (_id && value === 'delete') {
        setDeleteModalVisible(true);
        setAddonId(_id);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [deleteProductAddon, updateProductAddon],
  );

  const toggleEditVisible = (productAddon?: ProductAddonFragment) => {
    if (!editProductAddonVisible) {
      setEditInfo(productAddon);
    }

    setEditProductAddonVisible((prev) => !prev);
  };

  const popoverContent = ({ status }: { status: ProductAddonStatus }) => [
    {
      title:
        status === 'ACTIVE'
          ? formatMessage({ id: 'button.Deactivate' })
          : formatMessage({ id: 'button.Activate' }),
      value: 'toggle',
      icon: (
        <ProjectSettingsIcon className="product-addons-table__icon product-addons-table__icon--gray" />
      ),
    },
    {
      title: formatMessage({ id: 'button.Edit' }),
      value: 'edit',
      icon: (
        <EditIcon className="product-addons-table__icon product-addons-table__icon--gray" />
      ),
    },
    {
      title: formatMessage({ id: 'button.Delete' }),
      value: 'delete',
      icon: <TrashIcon className="product-addons-table__icon" />,
    },
  ];

  return (
    <div className="product-addons-table">
      <Table
        dataSource={addons}
        currentPage={currentPage}
        total={total}
        limit={limit}
        fetchMore={fetchMore}
        rowKey="_id"
        onRow={({ _id }) => ({
          onClick: (e) => handleAddonView(e, _id),
        })}
        onChange={onChange}
        loading={dataLoading}
      >
        <Column title={formatMessage({ id: 'ID' })} dataIndex="_id" />
        <Column
          dataIndex="hasCommissions"
          render={(hasCommissions: boolean) =>
            !hasCommissions && (
              <Tooltip title={formatMessage({ id: 'noCommission' })}>
                <NoCommissionIcon />
              </Tooltip>
            )
          }
        />
        <Column title={formatMessage({ id: 'Name' })} dataIndex="name" sorter />
        {showProject && (
          <Column
            title={formatMessage({ id: 'Project' })}
            dataIndex="project"
            render={({ name }: { name: string }) => name}
          />
        )}
        <Column
          title={formatMessage({ id: 'Status' })}
          dataIndex="status"
          render={(status: string) => {
            if (status === ProductStatus.Active) {
              return (
                <Tag color="primary">{formatMessage({ id: 'Active' })}</Tag>
              );
            }

            return (
              <Tag color="warning">{formatMessage({ id: 'Inactive' })}</Tag>
            );
          }}
        />
        <Column
          title={formatMessage({ id: 'Price excl. VAT' })}
          dataIndex="price"
          render={(price: number) => `${price} kr`}
          sorter
        />
        <Column
          title={formatMessage({ id: 'VAT rate' })}
          dataIndex="vatRate"
          render={(vat: number) => `${vat} %`}
          sorter
        />
        <Column
          title={formatMessage({ id: 'Price incl. VAT' })}
          dataIndex="vatPrice"
          render={(vatPrice: number) => `${vatPrice} kr`}
          sorter
        />

        {isPermittedManaging && (
          <Column
            title={formatMessage({ id: 'Actions' })}
            key="actions"
            className="product-addons-table__actions"
            render={(_: undefined, productAddon: any) => {
              const productAddonId = productAddon?._id;
              const content = popoverContent(productAddon);

              return (
                <ItemsPopover
                  content={content}
                  onItemClick={(value: string) =>
                    handleMenuClick(value, productAddonId, productAddon)
                  }
                >
                  <DotsIcon className="product-addons-table__menu" />
                </ItemsPopover>
              );
            }}
          />
        )}
      </Table>

      <EditAddonModal
        refetch={refetch}
        visible={editProductAddonVisible}
        addon={editInfo}
        onCancel={toggleEditVisible}
      />
      <ModalWindow
        visible={deleteModalVisible}
        title={formatMessage({ id: 'Delete Addon' })}
        onOk={deleteProductAddon}
        okType="danger"
        confirmLoading={loading}
        okText={formatMessage({ id: 'button.Delete' })}
        onCancel={() => setDeleteModalVisible(false)}
      >
        <p>{formatMessage({ id: 'delete.addon.description' })}</p>
      </ModalWindow>
    </div>
  );
};

export default AddonsTable;
