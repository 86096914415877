import React from 'react';
import { Tooltip } from 'antd';
import { useIntl } from 'react-intl';
import { generatePath, useNavigate } from 'react-router-dom';
import { Tag, Table, Column } from 'components';
import { NoCommissionIcon } from 'assets/icons';
import { ProductFragment, ProductStatus } from 'api';
import { Links } from 'settings';

interface ProductsTablePropsI {
  limit: number;
  total?: number;
  loading: boolean;
  currentPage: number;
  showProject?: boolean;
  products: ProductFragment[];
  fetchMore: (page: number) => void;
  onChange: (pagination: any, filters: any, sorter: any) => void;
}

const ProductsTable = ({
  total,
  limit,
  loading,
  products,
  onChange,
  fetchMore,
  currentPage,
  showProject,
}: ProductsTablePropsI) => {
  const navigate = useNavigate();

  const { formatMessage } = useIntl();

  const handleProductView = (id: string | number) =>
    navigate(generatePath(Links.products.details, { id }));

  return (
    <div className="products-table">
      <Table
        dataSource={products}
        currentPage={currentPage}
        total={total}
        limit={limit}
        fetchMore={fetchMore}
        rowKey="_id"
        onRow={({ _id }) => ({
          onClick: () => handleProductView(_id),
        })}
        onChange={onChange}
        loading={loading}
      >
        <Column title={formatMessage({ id: 'ID' })} dataIndex="_id" />
        <Column
          dataIndex="hasCommissions"
          render={(hasCommissions: boolean) =>
            !hasCommissions && (
              <Tooltip title={formatMessage({ id: 'noCommission' })}>
                <NoCommissionIcon />
              </Tooltip>
            )
          }
        />
        <Column title={formatMessage({ id: 'Name' })} dataIndex="name" sorter />
        {showProject && (
          <Column
            title={formatMessage({ id: 'Project' })}
            dataIndex="project"
            render={({ name }: { name: string }) => name}
          />
        )}
        <Column
          title={formatMessage({ id: 'Status' })}
          dataIndex="status"
          render={(status: string) => {
            if (status === ProductStatus.Active) {
              return (
                <Tag color="primary">{formatMessage({ id: 'Active' })}</Tag>
              );
            }

            return (
              <Tag color="warning">{formatMessage({ id: 'Inactive' })}</Tag>
            );
          }}
        />
        <Column
          title={formatMessage({ id: 'Price excl. VAT' })}
          dataIndex="price"
          render={(price: number) => `${price} kr`}
          sorter
        />
        <Column
          title={formatMessage({ id: 'VAT rate' })}
          dataIndex="vatRate"
          render={(vat: number) => `${vat} %`}
          sorter
        />
        <Column
          title={formatMessage({ id: 'Price incl. VAT' })}
          dataIndex="vatPrice"
          render={(vatPrice: number) => `${vatPrice} kr`}
          sorter
        />
      </Table>
    </div>
  );
};

export default ProductsTable;
