import { RegretStopTime } from 'assets/icons';
import moment from 'moment';
import { useIntl } from 'react-intl';
import React from 'react';
import './regretPeriodBadge.less';
import { OrderContractStatus } from 'api';

interface RegretPeriodBadgeProps {
  dateString: string | null | undefined;
  contractStatus: OrderContractStatus;
  isRegrettable: boolean;
}

const RegretPeriodBadge = ({
  dateString,
  contractStatus,
  isRegrettable,
}: RegretPeriodBadgeProps) => {
  const { formatMessage } = useIntl();

  const contractIsSignedDuration = moment.duration(
    moment(dateString).diff(moment())
  );
  const days = dateString ? contractIsSignedDuration.days() : 0;
  const hours = dateString ? contractIsSignedDuration.hours() : 0;

  if (
    isRegrettable &&
    contractStatus === OrderContractStatus.Signed &&
    days >= 0
  ) {
    return (
      <>
        {days >= 0 ? (
          <div className="regret-period-badge">
            <RegretStopTime />
            <span>
              {formatMessage({ id: 'regrettTimeLeft' }, { days, hours })}
            </span>
          </div>
        ) : (
          <span>-</span>
        )}
      </>
    );
  }

  if (contractStatus !== OrderContractStatus.Signed) {
    return <span>-</span>;
  }

  return <span className="guaranted-lable">Guaranteed</span>;
};

export default RegretPeriodBadge;
