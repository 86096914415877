import React from 'react';
import { Tag } from 'components';
import { useIntl } from 'react-intl';
import { B2BOrderStatus, OrderContractStatus, OrderStatus } from 'api';

const OrderStatusTag = ({
  status,
  idDeleted,
  contractStatus,
}: {
  status?: string;
  idDeleted?: boolean;
  contractStatus?: string;
}) => {
  const { formatMessage } = useIntl();

  if (contractStatus === OrderContractStatus.Error) {
    return <span>-</span>;
  }

  if (idDeleted) {
    if (status === OrderStatus.Pending) {
      return <span>-</span>;
    }
    if (status === OrderStatus.Approved) {
      return <Tag color="success">{formatMessage({ id: 'Approved' })}</Tag>;
    }
    if (status === OrderStatus.Regretted) {
      return <Tag color="error">{formatMessage({ id: 'Regretted' })}</Tag>;
    }
  }

  if (!idDeleted) {
    if (status === OrderStatus.Regretted) {
      return <Tag color="error">{formatMessage({ id: 'Regretted' })}</Tag>;
    }
    if (
      contractStatus === OrderContractStatus.Unsigned &&
      status === OrderStatus.Pending
    ) {
      return <span>-</span>;
    }
    if (OrderContractStatus.Signed && status === OrderStatus.Pending) {
      return <Tag color="warning">{formatMessage({ id: 'Pending' })}</Tag>;
    }
    if (OrderContractStatus.Signed && status === OrderStatus.Approved) {
      return <Tag color="success">{formatMessage({ id: 'Approved' })}</Tag>;
    }
  }

  // for B2B
  if (status === B2BOrderStatus.Deleted) {
    return <Tag color="error">{formatMessage({ id: 'Deleted' })}</Tag>;
  }
  if (status === B2BOrderStatus.New) {
    return <Tag color="warning">{formatMessage({ id: 'Pending' })}</Tag>;
  }

  return <span>-</span>;
};

export default OrderStatusTag;
