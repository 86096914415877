import React, { ReactNode } from 'react';

import './tag.less';

interface TagPropsI {
  children?: ReactNode;
  color?: 'default' | 'primary' | 'success' | 'warning' | 'error';
}

const Tag = ({ children, color = 'default' }: TagPropsI) => (
  <div className={`tag tag--${color}`}>{children}</div>
);

export default Tag;
