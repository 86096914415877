import React, { useCallback, useEffect } from 'react';
import {
  RoleFragment,
  TeamFragment,
  ProductFragment,
  TeamMemberFragment,
  SalesChannelFragment,
} from 'api';
import { Form, Row, Col } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { FormattedMessage, useIntl } from 'react-intl';
import { NumberInput, Select, Option } from 'components';

import './discountForm.less';

const { Item: FormItem } = Form;

const discountTypeOptions = [
  {
    value: 'byQty',
    label: <FormattedMessage id="By quantity" />,
  },
  { value: 'byTeam', label: <FormattedMessage id="By team" /> },
  { value: 'byRole', label: <FormattedMessage id="By role" /> },
  { value: 'byUser', label: <FormattedMessage id="By user" /> },
];

interface DiscountFormPropsI {
  teams: TeamFragment[];
  roles: RoleFragment[];
  product?: ProductFragment | null;
  teamMembers: TeamMemberFragment[];
  salesChannels: SalesChannelFragment[];
  form: FormInstance;
  initialValues?: {
    discountType?: string;
    qty?: number;
    teamId?: string | null;
    roleId?: string | null;
    discount?: number;
    teamMemberId?: string | null;
  };
}

const DiscountForm = ({
  form,
  teams,
  roles,
  product,
  teamMembers,
  salesChannels,
  initialValues,
}: DiscountFormPropsI) => {
  const { formatMessage } = useIntl();

  const { price, vatPrice, vatRate } = product || {};

  const handleFinalPriceChange = () => {
    const finalPrice = form.getFieldValue('finalPrice');

    if (!vatPrice) {
      return;
    }

    if (
      typeof finalPrice === 'string' ||
      finalPrice === undefined ||
      finalPrice === null
    ) {
      return form.setFieldsValue({ discount: null });
    }

    if (finalPrice > vatPrice) {
      return form.setFieldsValue({ discount: 0 });
    }

    return form.setFieldsValue({
      discount: ((vatPrice - finalPrice) / vatPrice) * 100,
    });
  };

  const handleDiscountPercentChange = useCallback(
    () => {
      const discountPercent = form.getFieldValue('discount');

      if (!vatPrice) {
        return;
      }

      if (!discountPercent || typeof discountPercent === 'string') {
        return form.setFieldsValue({ finalPrice: vatPrice });
      }

      if (discountPercent > 100) {
        return form.setFieldsValue({ finalPrice: 0 });
      }

      return form.setFieldsValue({
        finalPrice: vatPrice * (1 - discountPercent / 100),
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    handleDiscountPercentChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Form
      className="discount-form"
      form={form}
      layout="vertical"
      requiredMark={false}
      initialValues={initialValues}
    >
      <div className="discount-form__block">
        <div className="discount-form__info">
          <div className="discount-form__label">
            {formatMessage({ id: 'Price excl. VAT' })}
          </div>
          <div className="discount-form__price">{price} kr</div>
        </div>
        <div className="discount-form__info">
          <div className="discount-form__label">
            {formatMessage({ id: 'Price incl. VAT' })}
          </div>
          <div className="discount-form__price">{vatPrice} kr</div>
        </div>
        <div className="discount-form__info">
          <div className="discount-form__label">
            {formatMessage({ id: 'VAT rate' })}
          </div>
          <div className="discount-form__price">{vatRate}%</div>
        </div>
      </div>

      <Row gutter={5}>
        <Col span={16}>
          <FormItem
            name="discountType"
            label={formatMessage({ id: 'Discount type' })}
            rules={[{ required: true }]}
          >
            <Select options={discountTypeOptions} />
          </FormItem>
        </Col>
        <Col span={8}>
          <FormItem
            name="qty"
            label={formatMessage({ id: 'Quantity' })}
            rules={[{ required: true }]}
          >
            <NumberInput
              min={1}
              placeholder={formatMessage({ id: 'placeholder.Type quantity' })}
            />
          </FormItem>
        </Col>
      </Row>

      <FormItem shouldUpdate noStyle>
        {({ getFieldValue }) => {
          const discountType = getFieldValue('discountType');

          if (discountType === 'byTeam') {
            return (
              <FormItem
                name="teamId"
                label={formatMessage({ id: 'Team' })}
                rules={[{ required: true }]}
              >
                <Select
                  placeholder={formatMessage({ id: 'placeholder.Select team' })}
                  allowClear
                >
                  {teams.map(({ _id, name }) => (
                    <Option key={_id} value={_id}>
                      {name}
                    </Option>
                  ))}
                </Select>
              </FormItem>
            );
          }

          if (discountType === 'byRole') {
            return (
              <FormItem
                name="roleId"
                label={formatMessage({ id: 'Role' })}
                rules={[{ required: true }]}
              >
                <Select
                  placeholder={formatMessage({ id: 'placeholder.Select role' })}
                  allowClear
                >
                  {roles.map(({ _id, name }) => (
                    <Option key={_id} value={_id}>
                      {name}
                    </Option>
                  ))}
                </Select>
              </FormItem>
            );
          }

          if (discountType === 'byUser') {
            return (
              <FormItem
                name="teamMemberId"
                label={formatMessage({ id: 'User' })}
                rules={[{ required: true }]}
              >
                <Select
                  placeholder={formatMessage({ id: 'placeholder.Select user' })}
                  optionFilterProp="children"
                  showSearch
                  allowClear
                >
                  {teamMembers.map(({ _id, fullName }) => (
                    <Option key={_id} value={_id}>
                      {fullName}
                    </Option>
                  ))}
                </Select>
              </FormItem>
            );
          }
        }}
      </FormItem>

      <Row gutter={5}>
        <Col span={12}>
          <FormItem
            name="discount"
            label={formatMessage({ id: 'Discount(%)' })}
            rules={[{ required: true }]}
          >
            <NumberInput
              min={0}
              max={100}
              precision={undefined}
              onChange={handleDiscountPercentChange}
              placeholder={formatMessage({ id: 'placeholder.Type discount' })}
            />
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem
            name="finalPrice"
            label={formatMessage({ id: 'Final price(kr)' })}
            rules={[{ required: true }]}
          >
            <NumberInput
              min={0}
              max={vatPrice}
              precision={2}
              onChange={handleFinalPriceChange}
              placeholder={formatMessage({ id: 'placeholder.Type discount' })}
            />
          </FormItem>
        </Col>
      </Row>

      <FormItem shouldUpdate noStyle>
        {({ getFieldValue }) => {
          const discountType = getFieldValue('discountType');

          return discountType !== 'byUser' ? (
            <FormItem
              name="salesChannelId"
              label={formatMessage({ id: 'Sales channel' })}
            >
              <Select
                placeholder={formatMessage({
                  id: 'placeholder.Select sales channel',
                })}
                allowClear
              >
                {salesChannels.map(({ _id, name }) => (
                  <Option key={_id} value={_id}>
                    {name}
                  </Option>
                ))}
              </Select>
            </FormItem>
          ) : null;
        }}
      </FormItem>
    </Form>
  );
};

export default DiscountForm;
