import React from 'react';
import { DatePicker, DatePickerProps } from 'components';

const DateFilter = (props: DatePickerProps) => (
  <DatePicker
    size="large"
    picker="date"
    format="DD/MM/YYYY"
    inputReadOnly
    {...props}
  />
);

export default DateFilter;
