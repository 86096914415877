import React, { useState, useEffect, useContext, useCallback, createContext } from 'react';
import { SignInInput, useSignInMutation, useCheckSignInStatusMutation } from 'api';
import { fetchRefreshToken } from 'utils';

const initialValues = {
  qrCode: '',
  sessionId: '',
  signInStatus: null,
  isAuth: false,
  setAuth: (isAuth: boolean) => undefined,
  getQrInfo: (variables: { variables: { input: SignInInput } }) => undefined,
  checkSignInStatus: () => undefined,
};

const authContext = createContext(initialValues);

const { Provider } = authContext;

const AuthProvider = (props: any) => {
  const [isAuth, setAuth] = useState(false);
  const [loading, setLoading] = useState(true);
  const [getQrInfo, { data: qrInfo }] = useSignInMutation();
  const [checkSignIn, { data: signInInfo }] = useCheckSignInStatusMutation();

  const qrCode = qrInfo?.signIn?.qrcode;
  const sessionId = qrInfo?.signIn?.sessionId;
  const tokens = signInInfo?.checkSignInStatus?.tokens;
  const signInStatus = signInInfo?.checkSignInStatus?.status;

  const _logOut = useCallback(() => {
    setAuth(false);
    localStorage.removeItem('auth');
    localStorage.removeItem('dashboardFilters');
    localStorage.removeItem('session');
  }, []);

  useEffect(() => {
    fetchRefreshToken()
      .then((response) => response.json())
      .then((result) => {
        const checkTokenStatus = result?.data?.refreshToken?.status;
        const updatedTokens = result?.data?.refreshToken?.tokens;

        if (checkTokenStatus !== 'SUCCESS') {
          getQrInfo();
          _logOut();
          return;
        }

        localStorage.setItem('auth', JSON.stringify(updatedTokens));
        setAuth(true);
      })
      .catch(_logOut)
      .finally(() => setLoading(false));
  }, [_logOut, getQrInfo]);

  const checkSignInStatus = useCallback(async () => {
    const data: any = localStorage.getItem('session');
    if (data) {
      const sessionData = JSON.parse(data);
      if (sessionData && sessionData?.sessionId) {
        try {
          await checkSignIn({
            variables: {
              input: { sessionId: sessionData.sessionId },
            },
          });
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error);
        }
      }
    }
  }, [checkSignIn]);

  useEffect(() => {
    if (!signInStatus) {
      return;
    }

    if (signInStatus !== 'SUCCESS') {
      getQrInfo();
    }

    if (signInStatus === 'SUCCESS') {
      localStorage.setItem('auth', JSON.stringify(tokens));
      checkSignInStatus();
      setAuth(true);
    }
  }, [signInStatus, tokens, getQrInfo, checkSignInStatus]);

  if (loading) {
    return null;
  }

  return (
    <Provider
      value={{
        isAuth,
        setAuth,
        checkSignInStatus,
        getQrInfo,
        qrCode,
        sessionId,
        signInStatus,
      }}
      {...props}
    />
  );
};

const useAuthContext = () => useContext(authContext);

export { AuthProvider, authContext, useAuthContext };
