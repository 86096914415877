import React from 'react';
import { Moment } from 'moment';
import { DatePicker, Select } from 'components';
import { Input } from 'antd';
import { FormattedMessage } from 'react-intl';
import { RangeValue } from 'rc-picker/lib/interface';

import './licenseRangeFilter.less';

const { RangePicker } = DatePicker;
const { Group: InputGroup } = Input;

const licenseRangeType = [
  { value: 'end', label: <FormattedMessage id="License end" /> },
  { value: 'start', label: <FormattedMessage id="License start" /> },
  { value: 'duration', label: <FormattedMessage id="License duration" /> },
];

interface LicenseRangeFilterPropsI {
  onRangeTypeChange: (value: string) => void;
  onRangeDateChange: (dates: RangeValue<Moment>) => void;
}

const LicenseRangeFilter = ({ onRangeTypeChange, onRangeDateChange }: LicenseRangeFilterPropsI) => (
  <InputGroup className="license-range-filter" compact>
    <Select
      size="large"
      options={licenseRangeType}
      defaultValue="end"
      onChange={onRangeTypeChange}
    />
    <RangePicker
      picker="date"
      size="large"
      separator="-"
      format="DD/MM/YYYY"
      onCalendarChange={onRangeDateChange}
    />
  </InputGroup>
);

export default LicenseRangeFilter;
