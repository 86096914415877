import React from 'react';

import { useIntl } from 'react-intl';
import { Tooltip } from 'antd';
import classnames from 'classnames';
import { NavLink } from 'react-router-dom';

import { Protected } from 'components';
import { useUserContext } from 'contexts';
import { myTeamRouteConfig, navigation } from './configs';
import './navigation.less';

interface NavigationPropsI {
  collapsed: boolean;
}

const Navigation = ({ collapsed }: NavigationPropsI) => {
  const { formatMessage } = useIntl();
  const { onlyTeamData } = useUserContext();

  return (
    <nav className="navigation">
      {navigation.map(
        (
          navGroup: {
            name: string;
            route: string;
            action: string | string[];
            icon: any;
          }[],
          index,
        ) => (
          <div key={index} className="navigation__group">
            {navGroup.map((menuConfig) => {
              const config =
                menuConfig.route === '/teams' && onlyTeamData
                  ? myTeamRouteConfig
                  : menuConfig;
              const { icon, name, action, route } = config;
              const Icon = icon;

              const link = (
                <NavLink
                  to={route}
                  className={({ isActive }) =>
                    classnames('navigation__item', {
                      'order-link': name === 'New order',
                      // name === 'New order' && isActive,
                      'navigation__item--active': isActive,
                      collapsed,
                    })
                  }
                >
                  <Icon className="navigation__icon" />
                  <span className="navigation_text">
                    {formatMessage({ id: `navigation.${name}` })}
                  </span>
                </NavLink>
              );

              return (
                <Protected key={name} action={action}>
                  {collapsed ? (
                    <Tooltip
                      placement="right"
                      title={name}
                      color="#1B2028"
                      overlayInnerStyle={{
                        borderRadius: '6px',
                      }}
                      overlayStyle={{
                        paddingLeft: '23px',
                      }}
                    >
                      {link}
                    </Tooltip>
                  ) : (
                    link
                  )}
                </Protected>
              );
            })}
          </div>
        ),
      )}
    </nav>
  );
};

export default Navigation;
