import React, { useRef } from 'react';
import { InputNumber } from 'antd';
import { InputNumberProps } from 'antd/lib/input-number';

import './numberInput.less';

const NumberInput = (props: InputNumberProps) => {
  const inputRef = useRef<any>();

  const handlePressEnter = () => {
    if (inputRef.current) {
      return inputRef.current.blur();
    }

    return null;
  };

  return (
    <InputNumber
      className="number-input"
      ref={inputRef || null}
      onPressEnter={handlePressEnter}
      precision={0}
      {...props}
    />
  );
};

export default NumberInput;
