import React from 'react';
import { Card } from 'antd';
import { useIntl } from 'react-intl';
import { TeamMemberFragment } from 'api';
import { Link } from 'react-router-dom';
import { defaultFormatPhoneNumber } from 'utils';
import { Description, CreditStatus, DescriptionItem, Title } from 'components';

interface ClientInfoPropsI {
  className?: string;
  personId?: string;
  email?: string | null;
  address?: string | null;
  fullName?: string | null;
  creditCheckCode?: number;
  customerId?: string | null;
  phoneNumber?: string | null;
  assignee?: TeamMemberFragment;
  invoicingAddress?: string | null;
}

const ClientInfo = ({
  email,
  address,
  personId,
  fullName,
  assignee,
  className,
  customerId,
  phoneNumber,
  creditCheckCode,
  invoicingAddress,
}: ClientInfoPropsI) => {
  const { formatMessage } = useIntl();

  const assigneeId = assignee?._id;
  const assigneeFullName = assignee?.fullName;

  return (
    <Card
      className={className ? `${className} client-info` : 'client-info'}
      bordered={false}
    >
      <Title size="small">{formatMessage({ id: 'Client information' })}</Title>

      <Description column={1}>
        <DescriptionItem label={formatMessage({ id: 'Customer ID' })}>
          {customerId}
        </DescriptionItem>
        <DescriptionItem label={formatMessage({ id: 'Client name' })}>
          {fullName}
        </DescriptionItem>
        <DescriptionItem label={formatMessage({ id: 'Client address' })}>
          {address}
        </DescriptionItem>
        {invoicingAddress && (
          <DescriptionItem label={formatMessage({ id: 'Inv. address' })}>
            {invoicingAddress}
          </DescriptionItem>
        )}
        {assignee && (
          <DescriptionItem label={formatMessage({ id: 'Sold by' })}>
            <Link to={`/users/view/${assigneeId}`}>{assigneeFullName}</Link>
          </DescriptionItem>
        )}
        <DescriptionItem
          className="no-wrap"
          label={formatMessage({ id: 'Client phone' })}
        >
          {defaultFormatPhoneNumber(phoneNumber as string)}
        </DescriptionItem>
        <DescriptionItem label={formatMessage({ id: 'Email' })}>
          {email}
        </DescriptionItem>
        <DescriptionItem
          className="no-wrap"
          label={formatMessage({ id: 'Personnummer' })}
        >
          {personId}
        </DescriptionItem>
        <DescriptionItem label={formatMessage({ id: 'Credit status' })}>
          <CreditStatus statusCode={creditCheckCode} />
        </DescriptionItem>
      </Description>
    </Card>
  );
};

export default ClientInfo;
