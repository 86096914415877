import React from 'react';
import { TeamMemberFragment, GetSalesHistoryForListQuery } from 'api';
import { Table } from 'components';
import { defaultDateFormat, ordersIdViewRoute } from 'utils';
import { FormattedMessage } from 'react-intl';

import './salesTable.less';
import { useNavigate } from 'react-router-dom';
import { renderProducts } from '../OrdersTable/OrdersTable';

const columns = [
  {
    title: (
      <span className="sales-table__title">
        <FormattedMessage id="Product" />
      </span>
    ),
    className: 'sales-table__products',
    width: '45%',
    render: renderProducts,
  },
  {
    title: <FormattedMessage id="Project" />,
    dataIndex: 'project',
    width: '10%',
    render: ({ name }: { name: string }) => name,
  },
  {
    title: <FormattedMessage id="Price" />,
    dataIndex: 'fullTotalWithAddons',
    render: (
      fullTotalWithAddons: string,
      { fullSubtotalWithAddons }: { fullSubtotalWithAddons: string },
    ) => {
      if (fullTotalWithAddons === fullSubtotalWithAddons) {
        return `${fullTotalWithAddons} kr`;
      }

      return (
        <div className="sales-table__price">
          <div className="sales-table__subtotal">
            {fullSubtotalWithAddons} kr
          </div>
          <div className="sales-table__total">{fullTotalWithAddons} kr</div>
        </div>
      );
    },
    width: '10%',
    sorter: true,
  },
  {
    title: <FormattedMessage id="Sales channel" />,
    dataIndex: 'salesChannel',
    width: '10%',
    render: ({ name }: { name: string }) => name,
  },
  {
    title: <FormattedMessage id="Date" />,
    dataIndex: 'createdAt',
    width: '10%',
    render: (createdAt: Date) => defaultDateFormat(createdAt),
    sorter: true,
  },
  {
    title: <FormattedMessage id="Sold by" />,
    dataIndex: 'assigneeFullName',
    render: (
      _: undefined,
      {
        assignee,
        commissionAmount,
      }: { assignee: TeamMemberFragment; commissionAmount: number },
    ) =>
      commissionAmount > 0 ? (
        <div className="sales-table__assignee">
          <div className="sales-table__fullName">{assignee?.fullName}</div>
          <div className="sales-table__commission">{commissionAmount} kr</div>
        </div>
      ) : (
        assignee?.fullName
      ),
    width: '15%',
    sorter: true,
  },
];

interface SalesTablePropsI {
  limit: number;
  total?: number;
  loading: boolean;
  currentPage: number;
  sales: GetSalesHistoryForListQuery['getSalesHistoryForList']['nodes'];
  fetchMore: (page: number) => void;
  onChange: (pagination: any, filters: any, sorter: any) => void;
}

const SalesTable = ({
  total,
  limit,
  sales,
  loading,
  onChange,
  fetchMore,
  currentPage,
}: SalesTablePropsI) => {
  const navigate = useNavigate();

  return (
    <div className="sales-table">
      <Table
        dataSource={sales}
        currentPage={currentPage}
        total={total}
        limit={limit}
        fetchMore={fetchMore}
        columns={columns}
        rowKey="_id"
        onRow={({ orderId }) => ({
          onClick: () => navigate(ordersIdViewRoute(orderId)),
        })}
        onChange={onChange}
        loading={loading}
        tableLayout="auto"
      />
    </div>
  );
};

export default SalesTable;
