import React from 'react';
import { Table } from 'components';
import { PartnerStoreFragment } from 'api';
import { FormattedMessage } from 'react-intl';
import { generatePath, useNavigate } from 'react-router-dom';
import { defaultDateFormat, defaultFormatPhoneNumber } from 'utils';

import './partnerStoresTable.less';
import { Links } from '../../../settings';

const columns = [
  {
    title: <FormattedMessage id="Store name" />,
    dataIndex: 'name',
    sorter: true,
  },
  {
    title: <FormattedMessage id="Address" />,
    dataIndex: 'address',
    className: 'partner-stores-table__middle',
  },
  {
    title: <FormattedMessage id="Contact person" />,
    dataIndex: 'contactPerson',
  },
  {
    title: <FormattedMessage id="Phone number" />,
    className: 'no-wrap',
    dataIndex: 'phoneNumber',
    render: (phoneNumber: string) => defaultFormatPhoneNumber(phoneNumber),
  },
  {
    title: <FormattedMessage id="Email" />,
    dataIndex: 'email',
  },
  {
    title: <FormattedMessage id="Licenses" />,
    dataIndex: 'totalLicenseCount',
    className: 'partner-stores-table__bold',
    render: (
      totalLicenseCount: string,
      { activatedLicenseCount }: { activatedLicenseCount: string },
    ) => `${activatedLicenseCount}/${totalLicenseCount}`,
    sorter: true,
  },
  {
    title: <FormattedMessage id="Date added" />,
    dataIndex: 'createdAt',
    render: (createdAt: Date) => defaultDateFormat(createdAt),
    sorter: true,
  },
];

interface ListPartnerStoresPropsI {
  limit: number;
  total?: number;
  loading: boolean;
  currentPage: number;
  fetchMore: (page: number) => void;
  partnerStores: PartnerStoreFragment[];
  onChange: (pagination: any, filters: any, sorter: any) => void;
}

const ListPartnerStores = ({
  total,
  limit,
  loading,
  onChange,
  fetchMore,
  currentPage,
  partnerStores,
}: ListPartnerStoresPropsI) => {
  const navigate = useNavigate();

  const handlePartnerStoreView = (id: string | number) =>
    navigate(generatePath(Links.partnerStores.details, { id }));

  return (
    <div className="partner-stores-table">
      <Table
        dataSource={partnerStores}
        currentPage={currentPage}
        total={total}
        limit={limit}
        fetchMore={fetchMore}
        columns={columns}
        rowKey="_id"
        onRow={({ _id }) => ({
          onClick: () => handlePartnerStoreView(_id),
        })}
        onChange={onChange}
        loading={loading}
      />
    </div>
  );
};

export default ListPartnerStores;
