import React, { lazy } from 'react';
import { useAuthContext } from 'contexts';
import { ErrorBoundary } from 'components';

import './App.less';

const Home = lazy(() => import('pages/Home'));
const Auth = lazy(() => import('pages/Auth'));

function App() {
  const { isAuth } = useAuthContext();

  return (
    <ErrorBoundary>
      <div className="App">{isAuth ? <Home /> : <Auth />}</div>
    </ErrorBoundary>
  );
}

export default App;
