const phoneValidationRegExp = /^(([+]46)\s*(7)|07)\s*(\d{5})\s*(\d{3})$/;
const validatedPhoneNumber = (phoneNumber: string) =>
  phoneValidationRegExp.test(phoneNumber) ? phoneNumber : '';

const phonePrepare = (phoneNumber: string) =>
  /^\+46/.test(phoneNumber as string) ? phoneNumber?.slice(3) : phoneNumber;

const phoneTransform = (phoneNumber: string) => `+46${phoneNumber}`;

const phonePrepareAndTransform = (phoneNumber: string) =>
  /^\+46/.test(phoneNumber as string) ? phoneNumber : phoneTransform(phoneNumber.slice(1));

const defaultFormatPhoneNumber = (phoneNumber: string): string => {
  let phone: string;
  if (phoneNumber) {
    const swedishCode = /^\+46/.test(phoneNumber);
    const cleaned = phoneNumber.replace(/\D/g, '');
    if (cleaned.length > 10 && swedishCode) {
      phone = `0${cleaned.slice(2)}`;
    } else {
      phone = cleaned;
    }
    return phone;
  }
  return '';
};

const cleanSearchResult = (searchBy: string, searchField = '') =>
  searchBy === 'phoneNumberLike' ? searchField?.replace(/[- )(]/g, '') : searchField;

export {
  phonePrepare,
  phoneTransform,
  defaultFormatPhoneNumber,
  cleanSearchResult,
  phoneValidationRegExp,
  validatedPhoneNumber,
  phonePrepareAndTransform,
};
