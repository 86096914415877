import React, { memo } from 'react';
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';

import './breadcrumbs.less';

const { Item: BreadcrumbItem } = Breadcrumb;

interface BreadcrumbsPropsI {
  breadcrumbs: { name?: string | null; url?: string }[];
}

const Breadcrumbs = ({ breadcrumbs }: BreadcrumbsPropsI) => (
  <Breadcrumb className="breadcrumbs">
    {breadcrumbs.map(({ name, url }) => {
      if (!name) {
        return null;
      }

      if (url) {
        return (
          <BreadcrumbItem key={name}>
            <Link to={url}>{name}</Link>
          </BreadcrumbItem>
        );
      }
      return <BreadcrumbItem key={name}>{name}</BreadcrumbItem>;
    })}
  </Breadcrumb>
);

export default memo(Breadcrumbs);
