import React from 'react';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { LinkIon, CustomerWhiteIcon } from 'assets/icons';

import './linkToCustomerPage.less';

const LinkToCustomerPage = ({ customerId }: { customerId?: string | null }) => {
  const { formatMessage } = useIntl();

  return customerId ? (
    <Link
      to={`/customers/view/${customerId}`}
      target="_blank"
      rel="noopener noreferrer"
      className="link-to-customer"
    >
      <CustomerWhiteIcon />
      <span>{formatMessage({ id: 'Open customer page' })}</span>
      <LinkIon width={14} height={14} />
    </Link>
  ) : null;
};

export default LinkToCustomerPage;
