import './financeSystemBadge.less';
import React from 'react';
import { Spin } from 'antd';
import { CheckIcon } from 'assets/icons';
import { LoadingOutlined } from '@ant-design/icons';
import { FinanceSystem, FinanceSystemSyncStatus } from 'api';
import {
  fortnox,
  fortnoxLight,
  fortnoxGrey,
  riverty,
  rivertyGrey,
  rivertyLight,
  clock,
  check,
} from 'assets/images';

const antIcon = <LoadingOutlined style={{ fontSize: 14 }} spin />;

const FinanceSystemBadge = ({
  type,
  status,
  active,
  loading,
  dissabled,
  onFinancialChange,
}: FinanceSystemBadgeProps) => {
  const changeFinSyst = (value: string) => {
    if (onFinancialChange && !dissabled) {
      onFinancialChange(value);
    }
  };

  const getIconLink = () => {
    if (active) {
      return type === FinanceSystem.Fortnox ? fortnoxLight : rivertyLight;
    }
    if (!active && dissabled) {
      return type === FinanceSystem.Fortnox ? fortnoxGrey : rivertyGrey;
    }
    return type === FinanceSystem.Fortnox ? fortnox : riverty;
  };

  return (
    <div
      onClick={() => changeFinSyst(type)}
      className={`FinancialBadgeContainer ${active && 'Badge-active'} ${
        dissabled && !active && 'Badge-inactive-white'
      } ${dissabled && active && 'Badge-inactive'}`}
    >
      {active && (
        <CheckIcon
          className={`FinancialBadgeContainer__check-icon ${
            dissabled &&
            type === FinanceSystem.Fortnox &&
            'check-icon-dissabled-white'
          } ${
            dissabled &&
            type === FinanceSystem.Arvato &&
            'check-icon-dissabled-white'
          }`}
        />
      )}
      {loading && <Spin indicator={antIcon} />}
      {type === FinanceSystem.Fortnox && (
        <img
          src={getIconLink()}
          alt={FinanceSystem.Fortnox}
          className="FinancialBadgeContainer__icon"
        />
      )}

      {type === FinanceSystem.Arvato && (
        <img
          src={getIconLink()}
          alt={FinanceSystem.Arvato}
          className="FinancialBadgeContainer__icon"
        />
      )}
      {status !== undefined && (
        <div>
          {[
            FinanceSystemSyncStatus.Full,
            FinanceSystemSyncStatus.Partial,
            FinanceSystemSyncStatus.Transition,
          ].includes(status) ? (
            <img
              src={check}
              alt=""
              className="FinancialBadgeContainer__synch-icon"
            />
          ) : (
            <img
              src={clock}
              alt=""
              className="FinancialBadgeContainer__synch-icon"
            />
          )}
        </div>
      )}
    </div>
  );
};

interface FinanceSystemBadgeProps {
  type: FinanceSystem;
  active?: boolean;
  loading?: boolean;
  dissabled?: boolean;
  status?: FinanceSystemSyncStatus;
  onFinancialChange?: (value: string) => void;
}

export default FinanceSystemBadge;
