import React, { ReactNode } from 'react';
import classNames from 'classnames';

import './title.less';

interface TitlePropsI {
  className?: string;
  children: ReactNode;
  size?: 'small' | 'middle';
}

const Title = ({ children, size, className }: TitlePropsI) => (
  <h1 className={classNames('title', className, { [`title--${size}`]: size })}>{children}</h1>
);

export default Title;
