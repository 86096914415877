import React from 'react';
import { Modal, Form } from 'antd';
import { useIntl } from 'react-intl';
import { useErrorsContext } from 'contexts';
import { Title, AddonForm } from 'components';
import {
  ProductAddonStatus,
  ProductFragment,
  useCreateProductAddonMutation,
} from 'api';
import { useNavigate } from 'react-router-dom';

const { useForm } = Form;

interface CreateAddonModalPropsI {
  id?: string;
  visible?: boolean;
  onCompleted?: () => void;
  onCancel: () => void;
  product?: ProductFragment | null;
  fromProductsList?: boolean;
}

interface CreateProductAddonValuesI {
  addonName?: string;
  price?: number;
  unitId?: string;
  vatRate?: number;
  productId?: string;
  isActive?: boolean;
  isPersonIdRequired?: boolean;
  isInStok?: boolean;
  freeMonths?: number;
  description?: string;
  isStockable?: boolean;
  hasFreeMonths?: boolean;
  isSubscription?: boolean;
  canBeTerminated?: boolean;
  licenseDurationMonths?: number;
  isCreditCheckRequired?: boolean;
}

const CreateAddonModal = ({
  id,
  visible,
  onCompleted,
  onCancel,
  product,
  fromProductsList = false,
}: CreateAddonModalPropsI) => {
  const navigate = useNavigate();

  const [form] = useForm();

  const { setErrors } = useErrorsContext();

  const { formatMessage } = useIntl();

  const productId = id || form.getFieldValue('productId');

  const [createProductAddon, { loading }] = useCreateProductAddonMutation({
    onCompleted({ createProductAddon }) {
      const { status, errors } = createProductAddon || {};
      const success = status === 'SUCCESS';

      if (success) {
        onCompleted?.();

        if (fromProductsList) {
          navigate(`/products/view/${productId}`, { state: { tab: 'addons' } });
        }

        return onCancel();
      }

      setErrors(errors);
    },
  });

  const { name } = product || {};

  const handleSubmit = ({
    unitId,
    addonName = '',
    canBeTerminated = false,
    isActive = false,
    isCreditCheckRequired = false,
    isInStok = false,
    isStockable = false,
    isSubscription = false,
    price = 0,
    vatRate = 0,
    isPersonIdRequired = false,
  }: CreateProductAddonValuesI) => {
    const productId = id || form.getFieldValue('productId');

    createProductAddon({
      variables: {
        input: {
          name: addonName,
          price,
          vatRate,
          isInStok,
          isPersonIdRequired,
          canBeTerminated,
          productId,
          isStockable,
          isSubscription,
          ...(unitId ? { unitId } : {}),
          // TODO: remove when BE ready
          licenseDurationMonths: 1,
          freeMonths: 0,
          isCreditCheckRequired,
          status: isActive
            ? ProductAddonStatus.Active
            : ProductAddonStatus.Inactive,
        },
      },
    });
  };

  return (
    <Modal
      className="create-addon-modal"
      width={460}
      open={visible}
      title={
        <Title size="middle">
          {formatMessage({ id: 'Add addon for ' })}
          {name}
        </Title>
      }
      okText={formatMessage({ id: 'button.Create' })}
      cancelText={formatMessage({ id: 'button.Cancel' })}
      onCancel={onCancel}
      onOk={() => form.validateFields().then(handleSubmit)}
      confirmLoading={loading}
      destroyOnClose
    >
      <AddonForm
        product={product}
        initialValues={{
          projectId: product?.projectId,
          isActive: true,
          isCreditCheckRequired: true,
        }}
        form={form}
        fromProductsList={fromProductsList}
      />
    </Modal>
  );
};

export default CreateAddonModal;
