import React from 'react';
import {
  RoleFragment,
  TeamFragment,
  ProductFragment,
  TeamMemberFragment,
  SalesChannelFragment,
  ProductDiscountStatus,
  useCreateProductDiscountMutation,
} from 'api';
import { Modal, Form } from 'antd';
import { useIntl } from 'react-intl';
import { useErrorsContext } from 'contexts';
import { Title, DiscountForm } from 'components';

const { useForm } = Form;

interface CreateDiscountModalPropsI {
  id: string;
  visible?: boolean;
  refetch: () => void;
  onCancel: () => void;
  teams: TeamFragment[];
  roles: RoleFragment[];
  product?: ProductFragment | null;
  teamMembers: TeamMemberFragment[];
  salesChannels: SalesChannelFragment[];
}

interface CreateDiscountFormValuesI {
  qty?: number;
  discount?: number;
  teamId?: string | null;
  roleId?: string | null;
  teamMemberId?: string | null;
  salesChannelId?: string | null;
}

const CreateDiscountModal = ({
  id,
  teams,
  roles,
  visible,
  refetch,
  product,
  onCancel,
  teamMembers,
  salesChannels,
}: CreateDiscountModalPropsI) => {
  const [form] = useForm();

  const { setErrors } = useErrorsContext();

  const { formatMessage } = useIntl();

  const [createDiscount, { loading }] = useCreateProductDiscountMutation({
    onCompleted({ createProductDiscount }) {
      const { status, errors } = createProductDiscount || {};
      const success = status === 'SUCCESS';

      if (success) {
        refetch();
        return onCancel();
      }

      setErrors(errors);
    },
  });

  const handleSubmit = ({
    qty = 0,
    discount = 0,
    teamId = null,
    roleId = null,
    teamMemberId = null,
    salesChannelId = null,
  }: CreateDiscountFormValuesI) => {
    createDiscount({
      variables: {
        input: {
          qty,
          teamId,
          roleId,
          discount,
          teamMemberId,
          productId: id,
          salesChannelId,
          status: ProductDiscountStatus.Active,
        },
      },
    });
  };

  return (
    <Modal
      className="create-discount-modal"
      width={420}
      visible={visible}
      title={
        <Title size="middle">{formatMessage({ id: 'Create discount' })}</Title>
      }
      okText={formatMessage({ id: 'button.Create' })}
      cancelText={formatMessage({ id: 'button.Cancel' })}
      onCancel={onCancel}
      onOk={() => form.validateFields().then(handleSubmit)}
      confirmLoading={loading}
      destroyOnClose
    >
      <DiscountForm
        form={form}
        teams={teams}
        roles={roles}
        product={product}
        teamMembers={teamMembers}
        salesChannels={salesChannels}
        initialValues={{ discountType: 'byQty' }}
      />
    </Modal>
  );
};

export default CreateDiscountModal;
