import React from 'react';
import { GetB2BOrdersForOrdersListQuery } from 'api';
import { useIntl } from 'react-intl';
import { generatePath, useNavigate } from 'react-router-dom';
import {
  Table,
  CreditStatus,
  OrderStatusTag,
  Column,
  CustomerType,
} from 'components';

import './b2bOrdersTable.less';
import { addDashToValue } from 'utils';
import { Links } from 'settings';

interface OrdersTablePropsI {
  limit: number;
  total?: number;
  loading: boolean;
  currentPage: number;
  orders: GetB2BOrdersForOrdersListQuery['getB2BOrdersForOrdersList']['nodes'];
  hasClientName?: boolean;
  fetchMore: (page: number) => void;
  onChange: (pagination: any, filters: any, sorter: any) => void;
}

const OrdersTable = ({
  total,
  limit,
  orders,
  loading,
  onChange,
  fetchMore,
  currentPage,
}: OrdersTablePropsI) => {
  const navigate = useNavigate();

  const { formatMessage } = useIntl();

  const handleOrderView = (id: string | number) =>
    navigate(generatePath(Links.orders.b2bView, { id }));

  const renderTotal = (
    _value: string,
    order: GetB2BOrdersForOrdersListQuery['getB2BOrdersForOrdersList']['nodes'][0],
  ) => {
    if (order.total === order.subtotal) {
      return `${order.subtotal} kr`;
    }

    return (
      <div className="orders-table__price">
        <div className="orders-table__subtotal">{order.total} kr</div>
        <div className="orders-table__total">{order.subtotal} kr</div>
      </div>
    );
  };

  return (
    <div className="orders-table">
      <Table
        dataSource={orders}
        currentPage={currentPage}
        total={total}
        limit={limit}
        fetchMore={fetchMore}
        rowKey="_id"
        onRow={({ _id }) => ({
          onClick: () => handleOrderView(_id),
        })}
        onChange={onChange}
        loading={loading}
      >
        <Column title={formatMessage({ id: 'Order ID' })} dataIndex="_id" />
        <Column
          title={formatMessage({ id: 'Company name' })}
          dataIndex="companyName"
          className="no-wrap"
        />
        <Column
          title={formatMessage({ id: 'Org. nummer' })}
          dataIndex="orgId"
          className="no-wrap"
          render={(orgId) => addDashToValue(orgId, CustomerType.B2B)}
        />
        <Column
          title={formatMessage({ id: 'Credit check' })}
          dataIndex="creditCheckCode"
          render={(creditCheckCode: number) => (
            <CreditStatus statusCode={creditCheckCode} />
          )}
        />
        <Column
          title={formatMessage({ id: 'Product' })}
          render={() => 'SoSafe'}
        />
        <Column
          title={formatMessage({ id: 'Total' })}
          dataIndex="subtotal"
          render={renderTotal}
          className="no-wrap"
          sorter={(a, b) => Number(a) - Number(b)}
        />
        <Column
          title={formatMessage({ id: 'Status' })}
          dataIndex="status"
          render={(status: string) => <OrderStatusTag status={status} />}
        />
      </Table>
    </div>
  );
};

export default OrdersTable;
