import React, { ReactNode } from 'react';
import { Space } from 'antd';
import classNames from 'classnames';

import './actionHeader.less';

interface ActionHeaderPropsI {
  title?: string;
  small?: boolean;
  left?: ReactNode;
  right?: ReactNode;
  className?: string;
}

const ActionHeader = ({
  left,
  right,
  small,
  title,
  className,
}: ActionHeaderPropsI) => (
  <div className={classNames('action-header', className)}>
    <div className="action-header__left">
      {title && (
        <h1
          className={classNames('action-header__title', {
            'action-header__title--small': small,
          })}
        >
          {title}
        </h1>
      )}

      {left && <Space>{left}</Space>}
    </div>

    {right && <Space className="action-header__right">{right}</Space>}
  </div>
);

export default ActionHeader;
