import React from 'react';
import { logOut } from 'utils';
import { useIntl } from 'react-intl';
import { Menu, Dropdown } from 'antd';
import { useUserContext } from 'contexts';
import { DownOutlined } from '@ant-design/icons';

import './header.less';

interface HeaderPropsI {
  className?: string;
}

const { Item } = Menu;

const Header = ({ className }: HeaderPropsI) => {
  const { formatMessage } = useIntl();

  const { userName, userRoleName } = useUserContext();

  const items = [
    {
      key: '0',
      label: (
        <Item className="header__item" onClick={logOut}>
          {formatMessage({ id: 'button.Log out' })}
        </Item>
      ),
    },
  ];

  return (
    <div className={`header ${className}`}>
      <div className="header__info">
        <div className="header__name">{userName}</div>
        <div className="header__role">{userRoleName}</div>
      </div>
      <Dropdown menu={{ items }}>
        <DownOutlined />
      </Dropdown>
    </div>
  );
};

export default Header;
