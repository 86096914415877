import React from 'react';
import Lottie from 'react-lottie';
import classNames from 'classnames';
import loaderAnimation from 'assets/animations/loaderAnimation.json';

import './preloader.less';

type PreloaderPropT = {
  show?: boolean;
  contained?: boolean;
  className?: string;
  small?: boolean;
  color?: 'grey' | 'purple';
};

const Preloader = ({
  show = true,
  small = false,
  contained,
  color,
}: PreloaderPropT) => {
  const animationOptions = {
    loop: true,
    autoplay: true,
    animationData: loaderAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid meet',
    },
  };

  return show ? (
    <div
      className={classNames('preloader', {
        'preloader--contained': contained,
        'preloader--small': small,
        [`preloader--${color}`]: color,
      })}
    >
      <Lottie options={animationOptions} isClickToPauseDisabled />
    </div>
  ) : null;
};

export default Preloader;
