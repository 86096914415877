import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import {
  setLocalGroupedBy,
  setLocalDateRange,
  transformMonthRange,
  setLocalFilterByRole,
  setLocalFilterByTeam,
  setLocalFilterByUser,
  setLocalFilterByProject,
  setLocalFilterByChannel,
  getLocalDashboardFilters,
  setLocalPeriod,
} from 'utils';
import { GroupByPeriod } from 'api';
import moment, { Moment } from 'moment';

const currentMonth = transformMonthRange(moment());

interface DashboardFiltersContextI {
  period: string;
  dateRange: Moment[];
  groupedBy: GroupByPeriod;
  filterByTeam: string | null;
  filterByUser: string | null;
  filterByRole: string | null;
  filterByProject: string | null;
  filterByChannel: string | null;
  setPeriod: (period: string) => void;
  setDateRange: (dateRange: Moment[] | null) => void;
  setGroupedBy: (groupedBy: GroupByPeriod) => void;
  setFilterByTeam: (teamId?: string | null) => void;
  setFilterByUser: (userId?: string | null) => void;
  setFilterByRole: (roleId?: string | null) => void;
  setFilterByProject: (projectId?: string | null) => void;
  setFilterByChannel: (channelId?: string | null) => void;
}

const dashboardFiltersContext = createContext<DashboardFiltersContextI>({
  filterByTeam: null,
  filterByUser: null,
  filterByRole: null,
  filterByProject: null,
  filterByChannel: null,
  period: 'today',
  dateRange: currentMonth,
  setPeriod: () => undefined,
  groupedBy: GroupByPeriod.Day,
  setFilterByTeam: () => undefined,
  setFilterByUser: () => undefined,
  setFilterByRole: () => undefined,
  setFilterByProject: () => undefined,
  setFilterByChannel: () => undefined,
  setDateRange: () => undefined,
  setGroupedBy: () => undefined,
});

const { Provider } = dashboardFiltersContext;

const DashboardFiltersProvider = (props: any) => {
  const {
    localPeriod,
    localDateRange,
    localGroupedBy,
    localFilterByTeam,
    localFilterByUser,
    localFilterByRole,
    localFilterByProject,
    localFilterByChannel,
  } = getLocalDashboardFilters();

  const [period, setPeriodState] = useState(localPeriod);
  const [dateRange, setDateRangeState] = useState(localDateRange);
  const [groupedBy, setGroupedByState] = useState(localGroupedBy);
  const [filterByTeam, setFilterByTeamState] = useState(localFilterByTeam);
  const [filterByUser, setFilterByUserState] = useState(localFilterByUser);
  const [filterByRole, setFilterByRoleState] = useState(localFilterByRole);
  const [filterByProject, setFilterByProjectState] = useState(localFilterByProject);
  const [filterByChannel, setFilterByChannelState] = useState(localFilterByChannel);

  const setFilterByTeam = (teamId?: string | null) => {
    setFilterByTeamState(teamId);
    setLocalFilterByTeam(teamId);
  };

  const setFilterByUser = (userId?: string | null) => {
    setFilterByUserState(userId);
    setLocalFilterByUser(userId);
  };

  const setFilterByRole = (roleId?: string | null) => {
    setFilterByRoleState(roleId);
    setLocalFilterByRole(roleId);
  };

  const setFilterByProject = (projectId?: string | null) => {
    setFilterByProjectState(projectId);
    setLocalFilterByProject(projectId);
  };

  const setFilterByChannel = (channelId?: string | null) => {
    setFilterByChannelState(channelId);
    setLocalFilterByChannel(channelId);
  };

  const setDateRange = (dateRange: Moment[] | null) => {
    setDateRangeState(dateRange);
    setLocalDateRange(dateRange);
  };

  const setGroupedBy = useCallback((groupedBy: GroupByPeriod) => {
    setGroupedByState(groupedBy);
    setLocalGroupedBy(groupedBy);
  }, []);

  const setPeriod = (period: string) => {
    setPeriodState(period);
    setLocalPeriod(period);
  };

  useEffect(() => {
    if (!dateRange) {
      return setGroupedBy(GroupByPeriod.Year);
    }
    if (dateRange) {
      if (dateRange[1].diff(dateRange[0], 'months') > 12) {
        return setGroupedBy(GroupByPeriod.Year);
      }
      if (
        dateRange[1].diff(dateRange[0], 'months') >= 1 &&
        dateRange[1].diff(dateRange[0], 'months') <= 12
      ) {
        return setGroupedBy(GroupByPeriod.Month);
      }

      if (
        dateRange[1].diff(dateRange[0], 'days') > 1 &&
        dateRange[1].diff(dateRange[0], 'months') <= 1
      ) {
        return setGroupedBy(GroupByPeriod.Day);
      }

      if (dateRange[1].diff(dateRange[0], 'hour') < 24) {
        return setGroupedBy(GroupByPeriod.Hour);
      }
    }

    return setGroupedBy(GroupByPeriod.Day);
  }, [dateRange, setGroupedBy]);

  return (
    <Provider
      value={{
        period,
        dateRange,
        groupedBy,
        setPeriod,
        filterByRole,
        filterByTeam,
        filterByUser,
        setDateRange,
        setGroupedBy,
        filterByChannel,
        filterByProject,
        setFilterByUser,
        setFilterByTeam,
        setFilterByRole,
        setFilterByChannel,
        setFilterByProject,
      }}
      {...props}
    />
  );
};

const useDashboardFilterContext = () => useContext(dashboardFiltersContext);

export { dashboardFiltersContext, DashboardFiltersProvider, useDashboardFilterContext };
