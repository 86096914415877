import React from 'react';
import {
  RoleFragment,
  TeamFragment,
  CommissionStatus,
  TeamMemberFragment,
  SalesChannelFragment,
  useCreateCommissionMutation,
} from 'api';
import { Modal, Form } from 'antd';
import { useIntl } from 'react-intl';
import { useErrorsContext } from 'contexts';
import { Title, CommissionForm } from 'components';

const { useForm } = Form;

interface CreateCommissionModalPropsI {
  id: string;
  visible?: boolean;
  refetch: () => void;
  onCancel: () => void;
  teams: TeamFragment[];
  roles: RoleFragment[];
  teamMembers: TeamMemberFragment[];
  salesChannels: SalesChannelFragment[];
}

interface CreateCommissionFormValuesI {
  qty?: number;
  size?: number;
  teamId?: string | null;
  roleId?: string | null;
  teamMemberId?: string | null;
  salesChannelId?: string | null;
}

const CreateCommissionModal = ({
  id,
  teams,
  roles,
  visible,
  refetch,
  onCancel,
  teamMembers,
  salesChannels,
}: CreateCommissionModalPropsI) => {
  const [form] = useForm();

  const { setErrors } = useErrorsContext();

  const { formatMessage } = useIntl();

  const [createCommission, { loading }] = useCreateCommissionMutation({
    onCompleted({ createCommission }) {
      const { status, errors } = createCommission || {};
      const success = status === 'SUCCESS';

      if (success) {
        refetch();
        return onCancel();
      }

      setErrors(errors);
    },
  });

  const handleSubmit = ({
    qty,
    size = 0,
    teamId = null,
    roleId = null,
    teamMemberId = null,
    salesChannelId = null,
  }: CreateCommissionFormValuesI) => {
    createCommission({
      variables: {
        input: {
          qty: qty || 0,
          size,
          teamId,
          roleId,
          teamMemberId,
          productId: id,
          salesChannelId,
          status: CommissionStatus.Active,
        },
      },
    });
  };

  return (
    <Modal
      className="create-commission-modal"
      width={360}
      visible={visible}
      title={
        <Title size="middle">
          {formatMessage({ id: 'Create commission' })}
        </Title>
      }
      okText={formatMessage({ id: 'button.Create' })}
      cancelText={formatMessage({ id: 'button.Cancel' })}
      onCancel={onCancel}
      onOk={() => form.validateFields().then(handleSubmit)}
      confirmLoading={loading}
      destroyOnClose
    >
      <CommissionForm
        form={form}
        teams={teams}
        roles={roles}
        teamMembers={teamMembers}
        salesChannels={salesChannels}
        initialValues={{ commissionType: 'byTeam', qty: 1 }}
      />
    </Modal>
  );
};

export default CreateCommissionModal;
