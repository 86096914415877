import React from 'react';
import { Table } from 'components';
import { generatePath, useNavigate } from 'react-router-dom';
import { TeamMemberFragment } from 'api';
import { Links } from 'settings';
import { columns } from './constants';

interface UsersTablePropsI {
  limit: number;
  total?: number;
  loading: boolean;
  currentPage: number;
  users: TeamMemberFragment[];
  fetchMore: (page: number) => void;
  onChange: (pagination: any, filters: any, sorter: any) => void;
}

const UsersTable = ({
  users,
  total,
  limit,
  loading,
  onChange,
  fetchMore,
  currentPage,
}: UsersTablePropsI) => {
  const navigate = useNavigate();

  const handleUserView = (id: string | number) =>
    navigate(generatePath(Links.users.details, { id }));

  return (
    <div className="users-table">
      <Table
        dataSource={users}
        currentPage={currentPage}
        total={total}
        limit={limit}
        fetchMore={fetchMore}
        columns={columns}
        rowKey="_id"
        onRow={({ _id }) => ({
          onClick: () => handleUserView(_id),
        })}
        onChange={onChange}
        loading={loading}
      />
    </div>
  );
};

export default UsersTable;
