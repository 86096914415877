import React from 'react';
import { Moment } from 'moment';
import { DatePicker } from 'components';
import { RangeValue } from 'rc-picker/lib/interface';

interface DatesRangeFilterPropsI {
  className?: string;
  onRangeDateChange: (date: RangeValue<Moment> | null) => void;
}

const DatesRangeFilter = ({
  onRangeDateChange,
  className,
}: DatesRangeFilterPropsI) => (
  <DatePicker.RangePicker
    className={className || ''}
    inputReadOnly
    size="large"
    format="DD/MM/YYYY"
    separator="-"
    getPopupContainer={(trigger) => trigger}
    picker="date"
    onChange={onRangeDateChange}
    placeholder={['From', 'To']}
  />
);

export default DatesRangeFilter;
